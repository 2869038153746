import { styled } from "@mui/material/styles";
import MuiAppBar from "@mui/material/AppBar";
import boxShadows from "assets/theme/base/boxShadows";

const drawerWidth = 274;

export const Main = styled("main", {
  shouldForwardProp: (prop) => prop !== "open" && prop !== "mobile",
})(({ theme, open, mobile }) => ({
  padding: theme.spacing(0),
  transition: theme.transitions.create("margin", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: "relative",
  width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
  marginLeft: open ? `${drawerWidth}px` : 0,
  ...(open && {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [`${[theme.breakpoints.down("lg")]}`]: {
    width: "100%",
    marginLeft: 0,
  },
  ...(mobile && {
    backgroundColor: theme.palette.grey.mb,
  }),
}));

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  position: "fixed",
  top: 0,
  left: 0,
  right: "auto",
  width: open ? `calc(100% - ${drawerWidth}px)` : "100%",
  marginLeft: open ? `${drawerWidth}px` : 0,
  boxShadow: boxShadows.xxl,
  ...(open && {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  [`${[theme.breakpoints.down("lg")]}`]: {
    width: "100%",
    marginLeft: 0,
  },
}));
