import flatten from "lodash/flatten";
// mui
import QuizRounded from "@mui/icons-material/QuizRounded";
// Helpers
import lazy from "helpers/LazyImport";
// features
import features from "config/features";
// layout
import BodyWrapper from "layout/bodyWrapper";
import Container from "layout/simpleContainer";
// comonents
const AllQuestions = lazy(() => import("../../views/Questions/AllQuestions"));
const QuestionDetails = lazy(() =>
  import("../../views/Questions/QuestionDetails")
);
const UploadQuestionPaper = lazy(() =>
  import("../../views/Questions/UploadQuestionPaper")
);
const CreateBoardExam = lazy(() => import("../../views/Questions/BoardExams"));
const QBIndex = lazy(() => import("../../views/Questions/QuestionBank"));
const ViewQuestionBank = lazy(() =>
  import("../../views/Questions/QuestionBank/components/ViewQuestionBank")
);
const UploadQuestions = lazy(() =>
  import("../../views/Questions/QuestionBank/components/AddMutipleQuestions")
);
const QBFiles = lazy(() =>
  import("../../views/Questions/QuestionBank/components/QBFiles")
);
// const Mapper = lazy(() =>
//   import("../../views/Questions/QuestionBank/components/Mapper")
// );
const MapFilesToFolders = lazy(() =>
  import("../../views/Questions/QuestionBank/components/MapFilesToFolders")
);
const TestCreation = lazy(() => import("../../views/Questions/TestCreation"));
const TopicSelection = lazy(() =>
  import("../../views/Questions/TopicSelection")
);
const Card = lazy(() => import("../../components/MobileSubModules"));

// const ViewOfflineSummary = lazy(() =>
//   import("../../views/Questions/ExamSummary")
// );
export default {
  component: <BodyWrapper />,
  title: "Questions",
  basePath: "app",
  path: "questions",
  index: <Card path="questions" />,
  features: flatten(Object.values(features.questions)),
  icon: <QuizRounded />,
  children: [
    {
      component: <Container />,
      title: "All Question Papers",
      basePath: "questions",
      path: "all-question-papers",
      features: features.questions.all_question_papers,
      index: <AllQuestions />,
      children: [
        {
          component: <QuestionDetails />,
          title: "Test Details",
          basePath: "all-question-papers",
          path: "view",
          features: [
            "see_questions",
            "edit_question",
            "solution_download",
            "keys_download",
            "paper_download",
            "monitor_students",
            "submit_test",
            "upload_key",
            "upload_topics",
            "modify_test",
            "conduct_test",
            "upload_solutions",
            "view_paper",
          ],
        },
      ],
    },
    {
      component: <UploadQuestionPaper />,
      title: "Upload Question Paper",
      basePath: "questions",
      path: "upload-question-papers",
      features: features.questions.upload_question_papers,
    },
    // {
    //   component: <AdminPortalComponent />,
    //   title: "Create Test Paper",
    //   basePath: "questions",
    //   adminPath: true,
    //   path: "test-paper-creation",
    //   icon: <FileUploadRoundedIcon />,
    //   authorizedRoles: [],
    // },
    {
      component: <CreateBoardExam />,
      title: "Create Board Exam",
      basePath: "questions",
      path: "board-exam",
      features: features.questions.board_exam,
    },
    {
      component: <Container />,
      title: "Question Bank",
      basePath: "questions",
      path: "question-bank",
      features: features.questions.question_bank,
      index: <QBIndex />,
      children: [
        {
          component: <ViewQuestionBank />,
          title: "View Question Bank",
          basePath: "question-bank",
          path: "view",
          features: [
            "view_question_bank",
            "edit_question_bank_question_edit",
            "approve_question",
            "reject_question",
            "unverified_question",
          ],
        },
        {
          component: <UploadQuestions />,
          title: "Upload Questions",
          basePath: "question-bank",
          path: "upload",
          features: ["upload_questions_to_question_bank"],
        },
        {
          component: <QBFiles />,
          title: "Question bank Files",
          basePath: "question-bank",
          path: "qbfiles",
          features: [
            "verified/unverified_view_questions",
            "verified/unverified_update_questions",
            "verified/unverified_delete_file",
            "verified/unverified_update_topics_of_file",
          ],
        },
        // {
        //   component: <Mapper />,
        //   title: "Topics Subject Mapper",
        //   basePath: "question-bank",
        //   path: "topicsubmapper",
        //   authorizedRoles: [],
        // },
        {
          component: <MapFilesToFolders />,
          title: "Map Files to Folders",
          basePath: "question-bank",
          path: "mapFilesToFolders",
          features: ["map_files_to_folder"],
        },
      ],
    },
    {
      component: <TestCreation />,
      title: "Test Creation",
      basePath: "questions",
      path: "test-creation",
      features: features.questions.test_creation,
    },
    {
      component: <TopicSelection />,
      title: "Topic Selection",
      basePath: "questions",
      path: "topic-selection",
      features: features.questions.topic_selection,
    },
    // {
    //   component: <ViewOfflineSummary />,
    //   title: "Exam Summary",
    //   basePath: "questions",
    //   path: "exam-summary",
    //   // features: features?.["offline-utilities"].upload_results,
    //   authorizedRoles: [],
    // },
  ],
};
