// respective modules
import organizationConfig from "config/modules/organization";
import admissionsConfig from "config/modules/admissions";
import settingsConfig from "config/modules/settings";
import financeConfig from "config/modules/finance";
import attendanceConfig from "config/modules/attendance";
import academics from "config/modules/academics";
import communications from "config/modules/communications";
import analysis from "config/modules/analysis";
import questions from "config/modules/questions";
import liveClasses from "config/modules/liveClasses";
import offLine from "config/modules/offLine";
import Expenses from "config/modules/Expenses";
import hostelConfig from "config/modules/hostel";
import transportMang from "config/modules/transportMang";
import payrollAndHr from "config/modules/payrollAndHr";
import InventoryMgt from "config/modules/InventoryMgt";
import dashboard from "config/modules/dashboard";

export const common = [
  analysis,
  communications,
  academics,
  questions,
  liveClasses,
  offLine,
  settingsConfig,
];
export default [
  dashboard,
  organizationConfig,
  admissionsConfig,
  financeConfig,
  attendanceConfig,
  ...common,
  payrollAndHr,
  InventoryMgt,
  transportMang,
  Expenses,
  hostelConfig,
];
