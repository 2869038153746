// import { useState } from "react";
import startCase from "lodash/startCase";
// myComponents

// material ui
import { Autocomplete, Tooltip } from "@mui/material";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Icon from "@mui/material/Icon";
// myComponents
import SoftTypography from "myComponents/SoftTypography";
import SoftBox from "myComponents/SoftBox";
// styles
import useStyles from "components/FormikFieldArrayComponents/styles";

export const RequiredLabel = ({
  required,
  name,
  label,
  noHeaderLabel,
  getComponent,
}) => (
  <Stack direction="row">
    {!noHeaderLabel && (
      <SoftBox display="flex">
        <SoftTypography variant="caption" fontWeight="bold">
          {required ? startCase(name || label) : startCase(name || label)}
        </SoftTypography>
      </SoftBox>
    )}

    <SoftTypography variant="caption" color="error" fontWeight="bold">
      {required ? "*" : null}
    </SoftTypography>
    {getComponent && (
      <SoftBox display="flex" ml={0.5}>
        {getComponent && getComponent()}
      </SoftBox>
    )}
  </Stack>
);
export default function SoftAutoComplete({
  list,
  selected,
  handleSelect,
  multiple,
  label,
  filled,
  required = false,
  optionLabelPropName,
  optionValuePropName,
  textFieldProps,
  helperText,
  noHeaderLabel,
  simpleOptions = false,

  getComponent,
  ...props
}) {
  const addProps = simpleOptions
    ? {}
    : {
        getOptionLabel: (option) =>
          option?.[optionLabelPropName || "label"] || "",
        isOptionEqualToValue: (option, item) =>
          option?.[optionValuePropName || "_id"] ===
          item?.[optionValuePropName || "_id"],

        // renderOption: (prop, option) => {
        //   const listItem = option[optionLabelPropName || "label"] || "";
        //   return (
        //     <li {...prop}>
        //       <Tooltip title={listItem} placement="left" arrow>
        //         <div>
        //           <span>{listItem}</span>
        //         </div>
        //       </Tooltip>
        //     </li>
        //   );
        // },
        renderOption: (prop, option) => {
          const listItem =
            option && typeof option === "object"
              ? option[optionLabelPropName || "label"] || ""
              : "";

          return (
            <li {...prop}>
              <Tooltip title={listItem} placement="left" arrow>
                <div>
                  <span>{listItem}</span>
                </div>
              </Tooltip>
            </li>
          );
        },
      };

  return (
    <SoftBox width="100%">
      <Autocomplete
        options={list}
        {...addProps}
        fullWidth
        value={selected || null}
        onChange={(e, i) => {
          handleSelect(e, i);
        }}
        renderInput={(params) => (
          <>
            <SoftBox ml={0.5} mb={0.5}>
              <SoftTypography
                component="label"
                variant="caption"
                fontWeight="bold"
              >
                {label ? (
                  <SoftBox ml={0.5} display="flex">
                    <RequiredLabel
                      label={label}
                      required={required}
                      noHeaderLabel={noHeaderLabel}
                      getComponent={getComponent}
                    />
                  </SoftBox>
                ) : null}
              </SoftTypography>
            </SoftBox>
            <TextField
              fullWidth
              className={useStyles().textFieldStyles}
              required={required}
              placeholder={`select ${label || ""}`}
              {...params}
              {...textFieldProps}
            />

            {helperText && (
              <SoftBox
                ml={0.5}
                mb={0.5}
                display="flex"
                jsutifyContent="center"
                alignItems="center"
              >
                <SoftTypography
                  component="label"
                  variant="caption"
                  // fontWeight="bold"
                >
                  {label ? (
                    <SoftBox ml={0.5} mt={0.5}>
                      <Icon sx={{ fontSize: "12px" }}>
                        <InfoOutlinedIcon color="error" />
                      </Icon>
                      &nbsp;
                      <SoftTypography
                        variant="caption"
                        // fontWeight="bold"
                        color="error"
                      >
                        {helperText}
                      </SoftTypography>
                    </SoftBox>
                  ) : null}
                </SoftTypography>
              </SoftBox>
            )}
          </>
        )}
        {...props}
      />
    </SoftBox>
  );
}
